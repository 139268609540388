<template>
  <div :style="'position: relative; height:46px; text-align:' + config.align">
    <div :style="'margin-top:5px; margin-bottom: 5px; width: 100%; height: ' + config.cellHeight + 'px;'">
      <van-button :disabled="isEditSaved"  @click.stop="buttonClick"  type="small" :color="config.backColor" style="border-radius: 5px; margin-left: 2%; height: 35px; width: 96%" >{{config.text}}
      </van-button>
    </div>
    <!--<van-button type="small" :color="config.backColor"  :style="'height: ' + config.cellHeight + 'px; line-height: ' + config.cellHeight + 'px;'" >-->
      <!--<div :style="'color:' + pageSetup.wordColor +'; width:' + config.width + 'px;'">{{config.text}}</div>-->
    <!--</van-button>-->
  </div>
</template>

<script>
    import { Field, Button } from 'vant'
    export default {
        name: 'nxMobileButton',
        components: {
            [Field.name]: Field,
            [Button.name]: Button
        },
        props: {       pageSetup: Object,
            value: String,
            label: String,
            config: Object,
            children: Array,
            field: Object,
            isEditSaved: Boolean,
            doAction: Function,
            execConsole: Function,
            dataRow: Object,
            isList: {
                type: Boolean,
                defalut: false
            },
            customRcidFieldname: String,
            mastertable: Object
        },
        data () {
            return {
                show: false
            }
        },
        watch: {
            dataRow () {
                console.log(this.dataRow)
            }
        },
        methods: {
            buttonClick (event) {
                const listAction = []
                listAction.push(this.config.actionID)
                if (this.isList) {
                    this.doAction(listAction, this.dataRow['sys'].ReportID, this.dataRow['sys'][this.customRcidFieldname])
                } else {
                    if (this.config.actionType === '2') {
                        this.execConsole(this.config.actionID)
                    } else {
                        this.doAction(listAction)
                    }
                }
                event.stopPropagation()
            }
        }
    }
</script>

<style scoped>

</style>
